import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { writeToLocalStore } from '../store/localStore';

const SettingsContext = createContext();

const defaultSettings = {
  isDarkThemeActive: false,
  menuTree: {},
  menuCollapsed: {},
};

export function SettingsProvider({ settings, children }) {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    writeToLocalStore('settings', JSON.stringify(mergedSettings));
  };

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object,
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
