import { SettingsProvider } from './src/context/SettingsContext';
import { readFromLocalStore } from './src/store/localStore';

export const wrapRootElement = ({ element }) => {
  let settings = null;
  try {
    const storedData = readFromLocalStore('settings');
    if (storedData) {
      settings = JSON.parse(storedData);
      return <SettingsProvider settings={settings}>{element}</SettingsProvider>;
    }
    return <SettingsProvider>{element}</SettingsProvider>;
  } catch (err) {
    return <SettingsProvider>{element}</SettingsProvider>;
  }
};

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This tutorial has been updated. ` + `Reload to display the latest version?`
  );
  if (answer === true) {
    window.location.reload();
  }
};
